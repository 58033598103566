import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Actions } from '../../components/library'

function actions() {
    return (
        <LibraryWrapper>
            <Actions 
                dismissOnClick={e => console.log('DISMISSED')}
                dismissLabel="Cancel"
                submitOnClick={e => console.log('SUBMIT')}
                submitLabel="Submit"
            />
            <hr />
            <Actions 
                dismissTo="/components"
                dismissLabel="Go Back"
                submitTo="/components"
                submitLabel="Next"
            />
            <hr />
            <Actions 
                submitOnClick={e => console.log('SUBMIT')}
                submitLabel="Only Forward"
            />
            <hr />
            <Actions 
                dismissTo="/components"
                dismissLabel="Go Back"
                submitTo="/components"
                submitLabel="Next"
                dismissDisabled={true}
                submitDisabled={true}
            />
        </LibraryWrapper>
    )
}
export default actions
